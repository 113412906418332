<template>
    <div>
        <form @submit.prevent>
            <div class="row">
                <div class="col-12">
                    <h4>{{ $t('acte.trigger.declencher.pension') }} - {{ horse_names }}</h4>
                </div>
                <div class="col-12" v-if="form_message !== ''">
                    <ErrorAlert :messageI18n="form_message" />
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="date_ouverture" class="col-form-label">{{ $t("horse.date_ouverture") }} *</label>
                        <e-datepicker id="date_ouverture" v-model="date_acte" :class="{ 'is-invalid': errors && errors.indexOf('DateOuverture') > -1 }"></e-datepicker>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="pension_type" class="col-form-label">{{ $t("horse.pension") }} *</label>
                        <e-select
                            v-model="pension_type_selected"
                            id="pension_type"
                            track-by="label"
                            label="label"
                            :placeholder="labelTitlePensionType"
                            :selectedLabel="selectedLabel"
                            :options="pension_type"
                            :loading="isLoadingPension"

                            group-values="pension_type"
                            group-label="accountingplan_label"
                            :group-select="false"

                            :searchable="true"
                            :allow-empty="true"
                            :show-labels="false"
                            :close-on-select="true"
                            :class="{ 'is-invalid': errors && errors.indexOf('PensionType') > -1 }"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>

                <div class="col-12" v-if="hasFacturationAccess && pension_type_selected">
                    <div class="form-group" v-if="pension_type_selected.article">

                        <div class="row">
                            <div class="col"><label for="qte">{{ $t('acte.articles') }}</label></div>
                            <div class="col"><label for="qte">{{ $t('acte.ht_before_remise') }}</label></div>
                            <div class="col"><label for="qte">{{ $t('acte.remise') }}</label></div>
                            <div class="col"><label for="qte">{{ $t('acte.ht') }}</label></div>
                            <div class="col"><label for="qte">{{ $t('acte.type') }}</label></div>
                        </div>
                        <div class="row" v-for="(article, index) in pension_type_selected.article" :key="index">
                            <div class="col">
                                <span class="input-group-text">
                                    {{ article.articles_label }}
                                </span>
                            </div>
                            <div class="col">
                                <b-form-input 
                                    type="text" 
                                    v-model="article.horsepensionarticle_ht_before_discount" 
                                    class="form-control"
                                    @change="reloadConditionLine(article.horsepensionarticle_id)"
                                ></b-form-input>
                            </div>
                            <div class="col">
                                <div class="d-flex">
                                    <b-input v-if="article.horsepensionarticle_discount_type.invoicediscounttype_type != 'none'" type="text" class="form-control" v-model="article.horsepensionarticle_discount_value" :disabled="article.disabled" @change="setDiscountValueCondition(article.horsepensionarticle_discount_value, article.horsepensionarticle_id)"/>
                                    <e-select
                                        v-model="article.horsepensionarticle_discount_type"
                                        id="discount_type"
                                        track-by="invoicediscounttype_id"
                                        label="invoicediscounttype_label"
                                        :selectedLabel="selectedLabel"
                                        :options="discount_type_formatted"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @open="onTableSelectOpen"
                                        @close="onTableSelectClose"
                                        @input="setDiscountValueCondition(article.horsepensionarticle_discount_value, article.horsepensionarticle_id)"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">{{ $t(option.invoicediscounttype_label) }}</template>
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                            </div>
                            <div class="col">
                                <b-form-input 
                                    type="text" 
                                    v-model="article.horsepensionarticle_ht" 
                                    class="form-control"
                                ></b-form-input>
                            </div>
                            <div class="col">
                                <e-select
                                    v-model="article.facturation_type"
                                    id="facturation_type"
                                    :placeholder="getTrad('horse.type_facturation')"
                                    :selectedLabel="selectedLabel"
                                    :options="facturation_type"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    :close-on-select="true"
                                    :disabled="true"
                                    :class="{ 'is-invalid': errors && errors.indexOf('facturation_type') > -1 }"
                                >
                                    <template slot="option" slot-scope="{ option }">{{ $t('global.'+option) }}</template>
                                    <template slot="singleLabel" slot-scope="{ option }">{{ $t('global.'+option) }}</template>
                                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                </e-select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-12">
                    <div class="text-center mt-5">
                         <button @click="ignore" class="btn btn-secondary rounded-pill mr-4">
                            <font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}
                        </button>
                        <button type="submit" v-on:click="checkForm" class="btn btn-primary rounded-pill">
                            {{ $t('global.ajouter') }} <font-awesome-icon v-if="!processing" :icon="['fal', 'plus-circle']"/> <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import Navigation from "@/mixins/Navigation.js"
    import Tools from "@/mixins/Tools.js"
    import HorseMixin from "@/mixins/Horse.js"
    import AccountingMixin from "@/mixins/Accounting.js"
    import PensionMixin from "@/mixins/Pension.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterTriggers from '@/mixins/shutters-manager/Triggers.js'
    import Common from '@/assets/js/common.js'
	import InvoiceMixin from "@/mixins/Invoice.js"
	import TableSelectMixin from "@/mixins/TableSelect.js"

    export default {
        name: "PensionAjoutTriggered",
        props: {
            horses_ids: { type: Array, default: () => [] },
            date_acte: { type: Date, default: () => new Date() },
            shutter_name: { type: String, default: () => "" },
            next: { type: Array, default: () => [] }
        },
        mixins: [Navigation, Tools, HorseMixin, AccountingMixin, PensionMixin, Shutter, ShutterTriggers, InvoiceMixin, TableSelectMixin],
        data () {
            return {
                selectedLabel: this.getTrad("global.selected_label"),
                labelTitlePensionType: this.getTrad("horse.search_pension_type"),
                pension_type: [],
                pension_type_selected: {},
                form_message: "",
                form: {},
                errors: [],
                facturation_type: [
                    'pension',
                    'frais'
                ],
                discount_type: [],
                default_form: [{
                    date_ouverture: null,
                    date_cloture: null,
                    pension_type: {},
                    close_previous: false
                }],
                isLoadingPension: true,
                processing: false,
                horses_infos: [],
                horse_names: ''
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.form_message = ""
                this.$emit('update:addOrEditReady', false)
                this.discount_type = await this.loadDiscountType()
                this.form = this.default_form

                this.form = this.form.map(form => {
                    form.date_ouverture = this.date_acte
                    return form
                })

                // this.getHorsesWhishlist(this.horses_ids)
                //         .then(res => this.horses_infos = res)
                this.formatteHorsesNames()

                // let horse_pension = {}
                // if(this.horse_pension)
                // {
                //     horse_pension = this.horse_pension.filter(horse_pension => horse_pension.horsepension_id == this.horsepension_id)[0]
                // }


                // if (this.form[0].date_ouverture === null)
                // {
                //     this.horse.forEach(async horse => {
                //         await this.getHorseActualPlace(this.horse.horse_id)
                //         .then(currentLocation => {
                //             if (currentLocation)
                //             {
                //                 this.form.date_ouverture = new Date(currentLocation.mouvement_date)
                //             }
                //             else
                //             {
                //                 this.form.date_ouverture = new Date()
                //             }
                //         })
                //     })
                // }

                if(Object.keys(this.pension_type).length == 0)
                {
                    let accountingplan_key = null
                    const accounting_plan = await this.loadAccountingPlansPensionType()
                    if(accounting_plan)
                    {
                        for(let i = 0; i < accounting_plan.length; i++)
                        {
                            if(accounting_plan[i].pension_type)
                            {
                                for(let j = 0; j < accounting_plan[i].pension_type.length; j++)
                                {
                                    accountingplan_key = this.pension_type.filter(pensiontype => pensiontype.accountingplan_id == accounting_plan[i].accountingplan_id)[0];
                                    
                                    for (let k = 0; k < accounting_plan[i].pension_type[j].article.length; k++) {
                                         accounting_plan[i].pension_type[j].article[k].horsepensionarticle_ht_before_discount = accounting_plan[i].pension_type[j].article[k].articles_ht
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_ht = accounting_plan[i].pension_type[j].article[k].articles_ht
                                        accounting_plan[i].pension_type[j].article[k].horsepensionarticle_discount_type = {invoicediscounttype_id: 0, invoicediscounttype_label: "-", invoicediscounttype_type: "none"}
                                        accounting_plan[i].pension_type[j].article[k].facturation_type = accounting_plan[i].pension_type[j].article[k].pensionarticle_facturationtype
                                    }
                                    
                                    if(accountingplan_key)
                                    {
                                        accountingplan_key.pension_type.push({
                                            id: accounting_plan[i].pension_type[j].pension_id,
                                            label: accounting_plan[i].pension_type[j].pension_label,
                                            accountingplan_id: accounting_plan[i].accountingplan_id,
                                            article: accounting_plan[i].pension_type[j].article
                                        })
                                    }
                                    else
                                    {
                                        this.pension_type.push({
                                            accountingplan_id: accounting_plan[i].accountingplan_id,
                                            accountingplan_label: accounting_plan[i].accountingplan_label,
                                            pension_type: [{
                                                id: accounting_plan[i].pension_type[j].pension_id,
                                                label: accounting_plan[i].pension_type[j].pension_label,
                                                accountingplan_id: accounting_plan[i].accountingplan_id,
                                                article: accounting_plan[i].pension_type[j].article
                                            }]
                                        })
                                    }
                                }
                            }
                        }
                    }
                    this.isLoadingPension = false
                }

                this.$emit('update:addOrEditReady', true)
            },
            checkForm: async function () {
                this.form_message = ""
                this.errors = []

                if(!this.form[0].date_ouverture)
                {
                    this.errors.push("DateOuverture")
                }

                if(!this.form[0].pension_type || Object.keys(this.form[0].pension_type).length == 0)
                {
                    this.errors.push("PensionType")
                }

                if(!this.form[0].date_cloture)
                {
                    this.form[0].date_cloture = null
                }

                if(this.errors.length == 0)
                {
                    let tab_promises = []
                    this.processing = true
                    this.horses_ids.forEach(async horse => {
                        tab_promises.push(new Promise(async (res, rej) => {
                            this.addPension(horse, this.form[0].date_ouverture, null, this.form[0].pension_type.id, true, this.pension_type_selected.article)
							.then(res)
							.catch(rej)
                        }))
                    })
                   
                    Promise.all(tab_promises)
                    .then(() => {
                        this.processing = false
                        if(this.next.length > 0) {
                            const triggered = this.next[0]
                            this.next.shift()
                            this.shutterPanel().close('pension')
                            this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                        } else { 
                            let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                            this.$router.push({ name: 'HorsePension', params: {horse_id} })
                        }
                    })
                    .catch(() => {
                        this.processing = false
                        this.failureToast()
                    })

                    return true
                }

                this.form_message = "error.LEP"
                return false
            },

            ignore() {
                if(this.next.length > 0) {
                    const triggered = this.next[0]
                    this.next.shift()
                    this.shutterPanel().close('pension')
                    this.setupNewTriggered(triggered.horses, triggered.date, triggered.type, this.horses_ids[0], this.next)
                } else { 
                    let horse_id = this.$sync.replaceWithReplicated('horse', this.horses_ids[0])
                    this.$router.push({ name: 'HorsePension', params: {horse_id} })
                }
            },

            async setDiscountValueCondition(discount_value, horsepensionarticle_id) {
				const elem_to_edit = this.pension_type_selected.article.find(elem => horsepensionarticle_id == elem.horsepensionarticle_id)
				elem_to_edit.horsepensionarticle_discount_value = discount_value
				this.reloadConditionLine(horsepensionarticle_id)
			},

			async reloadConditionLine(horsepensionarticle_id) {
				const elem_to_edit = this.pension_type_selected.article.find(elem => horsepensionarticle_id == elem.horsepensionarticle_id)

				// on recalcule d'abord le prix ht unitaire après remise
                const ht_before_discount = elem_to_edit.horsepensionarticle_ht_before_discount.toString().replace(',', '.').replace(/\s/g, '')
				const discount_type = elem_to_edit.horsepensionarticle_discount_type

                if(discount_type.invoicediscounttype_type != "none" && elem_to_edit.horsepensionarticle_discount_value)
                {
                    const discount_value = elem_to_edit.horsepensionarticle_discount_value.toString().replace(',', '.')

                    if(discount_type.invoicediscounttype_type == "numeraire") {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount - discount_value
                    }
                    else if(discount_type.invoicediscounttype_type == "pourcentage" && discount_value > 0) {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount - (ht_before_discount * (discount_value / 100))
                    }
                    else {
                        elem_to_edit.horsepensionarticle_ht = ht_before_discount
                    }
                }
				else {
					elem_to_edit.horsepensionarticle_ht = ht_before_discount
				}

				const ht = elem_to_edit.horsepensionarticle_ht

				// ensuite on calcule le HT total et le TTC
				elem_to_edit.horsepensionarticle_ht = await this.priceFormat(ht)
				elem_to_edit.horsepensionarticle_ttc = await this.priceFormat(ht * (1 + elem_to_edit.vat.vat_value))
			},

            async formatteHorsesNames() {
                const horses = await this.getHorsesByIds(this.horses_ids)
                this.horse_names = horses.map(horse => horse.horse_nom).join(', ')
            }
        },
        watch: {
            'pension_type_selected' (val) {
                this.form = this.form.map(form => {
                    form.pension_type = val
                    return form
                })
            },

            'date_acte' (val) {
                this.form = this.form.map(form => {
                    form.date_ouverture = val
                    return form
                })
            }
        },
        computed: {
             hasFacturationAccess() {
                return this.$store.state.userAccess.hasFacturationAccess
            },
            discount_type_formatted(){
				let tab = []

				tab.push({
					invoicediscounttype_id: 0,
					invoicediscounttype_label: "-",
					invoicediscounttype_type: "none"
				})

				for(let i = 0; i < this.discount_type.length; i++) {
					let current = this.discount_type[i]
					current.invoicediscounttype_label = this.getTrad(current.invoicediscounttype_label+"_short")
					tab.push(current)
				}

				return tab
			}

            // horsesName() {
            //     return this.horses_infos
            //             .map(h => h.horse_nom)
            //             .join(', ')
            // }
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
        }
    }
</script>
